import React from 'react';
import clx from 'classnames';

export enum ContainerSizes {
    xsmall = 'container-xsmall',
    small = 'container-small',
    medium = 'container-medium',
    large = 'container-large',
    xlarge = 'container-xlarge',
    none = '',
}

export enum ContainerGutters {
    none = 'container-gutters-none',
    small = 'container-gutters-small',
    medium = 'container-gutters-medium',
    large = 'container-gutters-large',
    xlarge = 'container-gutters-xlarge',
}

export enum ContainerTags {
    div = 'div',
    section = 'section',
    header = 'header',
    ul = 'ul',
}

interface Props extends React.HTMLAttributes<HTMLElement> {
    size?: keyof typeof ContainerSizes;
    gutter?: keyof typeof ContainerGutters;
    as?: keyof typeof ContainerTags;
    children: React.ReactNode;
    className?: string;
    disableCentring?: boolean;
}

/**
 * CSS container component with tokened size and gutter props.
 */
const Container = ({ children, as: Tag = 'div', size = 'medium', gutter = 'none', className, disableCentring = false, ...rest }: Props) => {
    const containerClassNames = clx('w-full', disableCentring ? '' : 'mx-auto', ContainerSizes[size], ContainerGutters[gutter], className);

    return (
        <Tag className={containerClassNames} {...rest}>
            {children}
        </Tag>
    );
};

export default Container;
