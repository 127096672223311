import jwtDecode from 'jwt-decode';
import get from 'lodash.get';
import queryString from 'query-string';
import dayjs from 'dayjs';

import affiliateService from '../../../data/services/affiliate.service';
import { localStorageGet, localStorageSet } from '../../../hooks/useLocalStorageSync';

const affiliateStorageExpiresFormat = 'YYYY-MM-DD hh:mm:ss';
const affiliateStorageKey = 'stored_affiliate_codes';

/**
 * Clears all expired affiliates from the stored affiliates in local storage.
 */
export const clearExpiredAffiliateCodes = () => {
    const storedAffiliates = localStorageGet(affiliateStorageKey) || {};

    Object.keys(storedAffiliates).forEach((key) => {
        const { expires } = storedAffiliates[key];

        // If the current date is after the expires at date.
        const hasExpired = dayjs().isAfter(dayjs(expires, affiliateStorageExpiresFormat));

        if (hasExpired) {
            // Remove the affiliate code from the stored affiliates object.
            delete storedAffiliates[key];
        }
    });

    // Set the filtered affiliates as the new value in local storage.
    localStorageSet(affiliateStorageKey, storedAffiliates);
};

/**
 * Gets an affiliate from local storage using the requested code as the key.
 */
const getAffiliateFromStorage = (code: string) => {
    const loggedAffiliateCodes = localStorageGet(affiliateStorageKey) || {};
    return loggedAffiliateCodes[code] || null;
};

/**
 * Stores an affiliate in local storage.
 */
const storeAffiliateInStorage = (code: string, token: string, daysTillExpire: number) => {
    const storedAffiliates = localStorageGet(affiliateStorageKey) || {};

    // Set the new affiliate in local storage using spread operator to retain the current ones.
    localStorageSet(affiliateStorageKey, {
        ...storedAffiliates,
        [code]: {
            data: {
                token,
            },
            expires: dayjs().add(daysTillExpire, 'day').format(affiliateStorageExpiresFormat),
        },
    });
};

export const validateAffiliateCode = async (code: string) => {
    // Remove expired affiliate codes from local storage.
    clearExpiredAffiliateCodes();

    // If we have more than 1 query param then dump remove the affiliate, otherwise remove the ? and put the full url in.
    const newUrl = queryString.exclude(window.location.search, ['affiliate']) || window.location.href.replace(window.location.search, '');
    // @ts-expect-error
    window.history.replaceState(null, document.title, [newUrl]);

    let isValid = false;
    let token;
    let daysTillExpire;

    try {
        // Get the affiliate token from local storage using the code provided.
        let jwt = getAffiliateFromStorage(code);

        // If it's not in local storage, make a request to the API to validate the affiliate code given.
        if (!jwt) {
            jwt = await affiliateService.validateAffiliate(code);
        }

        token = get(jwt, 'data.token', '');

        if (token === '') {
            throw new Error('No token returned from affiliate service.');
        }

        const decodedJwt = jwtDecode(token);

        daysTillExpire = parseInt(get(decodedJwt, 'expires_after', '0'), 10);
        if (Number.isNaN(daysTillExpire)) {
            daysTillExpire = 0;
        }

        isValid = true;
    } catch (error) {
        console.error(error);
    }

    // Store the affiliate in local storage, even if it came back as invalid.
    // If it is invalid, store the token for a day so that the API endpoint isn't spammed.
    // If it is valid, store the token for the amount of days the API endpoint returned.
    storeAffiliateInStorage(code, token || '', daysTillExpire || 1);
    return { isValid, token, daysTillExpire };
};
