import { useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import formatTimeRemaining from './helpers/formatTimeRemaining/formatTimeRemaining';

interface Props {
    cutOffTime?: string;
    onCountDownEnd?: () => void;
}

/**
 * Calculates the time remaining for delivery cut off time.
 *
 * Updates the state via an interval function. This function runs every second. It compares the previous
 * value with the next value to see if it has changed. if it has, the state is updated to trigger a rerender.
 * This will stop the countdown rerenders happening every second when only the days, hours and minutes are
 * displayed.
 */
const useDeliveryCutOffCountDown = ({ cutOffTime, onCountDownEnd }: Props) => {
    const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
    const interval = useRef<NodeJS.Timer | undefined>(undefined);
    const storedTimeRemaining = useRef('');

    useEffect(() => {
        if (!cutOffTime) {
            clearInterval(interval.current);
            setTimeRemaining(null);
            return;
        }

        interval.current = setInterval(() => {
            const now = dayjs();
            const dayjsCutOffTime = dayjs(cutOffTime, 'YYYY-MM-DD HH:mm:ss');
            const difference = dayjsCutOffTime.diff(now);

            if (difference <= 0) {
                clearInterval(interval.current);
                setTimeRemaining(null);
                if (onCountDownEnd) {
                    onCountDownEnd();
                }
                return;
            }

            const formattedTimeRemaining = formatTimeRemaining(dayjs.duration(difference));
            if (formattedTimeRemaining === storedTimeRemaining.current) {
                return;
            }

            storedTimeRemaining.current = formattedTimeRemaining;
            setTimeRemaining(formattedTimeRemaining);
        }, 1000);

        return () => clearInterval(interval.current); // eslint-disable-line consistent-return
    }, [cutOffTime, onCountDownEnd]);

    return timeRemaining;
};

export default useDeliveryCutOffCountDown;
