import React from 'react';

const IconCross = () => (
    <path
        fillRule="evenodd"
        data-testid="IconCross"
        d="M0.53253 1.59183C1.26549 0.826877 2.48087 0.799915 3.24715 1.53161L24 21.3477L44.7529 1.53161C45.5191 0.799915 46.7345 0.826877 47.4675 1.59183C48.2004 2.35678 48.1734 3.57004 47.4071 4.30173L26.7777 24L47.4071 43.6983C48.1734 44.43 48.2004 45.6432 47.4675 46.4082C46.7345 47.1731 45.5191 47.2001 44.7529 46.4684L24 26.6523L3.24715 46.4684C2.48087 47.2001 1.26549 47.1731 0.53253 46.4082C-0.200433 45.6432 -0.173424 44.43 0.592855 43.6983L21.2223 24L0.592855 4.30173C-0.173424 3.57004 -0.200433 2.35678 0.53253 1.59183Z"
    />
);

export default IconCross;
