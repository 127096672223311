import React from 'react';

const IconLinkedIn = () => (
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76323 11.5811C2.58108 11.5811 0 8.94539 0 5.76324C0 2.58029 2.58029 0 5.76323 0C8.94618 0 11.5265 2.58029 11.5265 5.76324C11.5265 8.94539 8.94432 11.5811 5.76323 11.5811ZM10.7446 47.9989H0.793133V15.9523H10.7446V47.9989ZM38.0593 47.9991H47.9893H48.0001V30.3955C48.0001 21.7812 46.1486 15.149 36.0772 15.149C31.2353 15.149 27.99 17.8062 26.6614 20.324H26.5221V15.9526H16.9778V47.9991H26.9186V32.1312C26.9186 27.9526 27.7114 23.9133 32.8854 23.9133C37.9843 23.9133 38.0593 28.6812 38.0593 32.3991V47.9991Z"
    />
);

export default IconLinkedIn;
