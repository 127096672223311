import React, { useEffect, useRef, ReactNode } from 'react';
import { Link } from 'gatsby';
import { CRAFT_URL } from '../../../config/config';

interface Element {
    slug: string;
}

interface LinkChild {
    id: string;
    type: string;
    title: string;
    url: string | null;
    urlSuffix: string | null;
    element: null | Element;
}

export interface MenuNode {
    id: string;
    type: string;
    title: string;
    url: string | null;
    urlSuffix: string | null;
    element: null | Element;
    navigationIcon: Array<'doctor'>;
    children: LinkChild[];
}

export interface CraftMenuLinkProps {
    menuNode: MenuNode | LinkChild;
    className?: string | undefined | null;
    activeClassName?: string | undefined | null;
    children: ReactNode;
    onClick?: () => void;
    title?: string;
}

/** Creates a way for our menu system to work out the link that needs to be displayed and whether its read only or not. */
const CraftMenuLink: React.FC<CraftMenuLinkProps> = ({ menuNode, className, children, activeClassName, onClick, ...rest }) => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    const handleButtonClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, 500);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    switch (menuNode.type) {
    case 'craft\\elements\\Entry':
        return (
            <Link
                {...rest}
                activeClassName={activeClassName!}
                to={menuNode.url!.replace(CRAFT_URL, '').replace('https://tip-cms.builtbyelement.io', '')}
                className={className!}
                onClick={handleButtonClick}
            >
                {children}
            </Link>
        );

    case 'verbb\\navigation\\nodetypes\\PassiveType':
        return (
            <span {...rest} className={className!}>
                {children}
            </span>
        );

    default:
        return (
            <Link
                {...rest}
                activeClassName={activeClassName!}
                to={menuNode.url || ''}
                className={className!}
                onClick={handleButtonClick}
            >
                {children}
            </Link>
        );
    }
};

export default CraftMenuLink;
