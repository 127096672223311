import React, { Fragment } from 'react';

const IconHome = () => (
    <Fragment>
        <path
            d="M4.80875 20.2455C4.7629 19.6083 5.0242 18.9875 5.51195 18.575L22.7083 4.02876C23.454 3.39806 24.546 3.39805 25.2916 4.02876L42.488 18.575C42.9758 18.9875 43.2371 19.6083 43.1912 20.2455L41.4714 44.1436C41.3961 45.1897 40.5254 46 39.4765 46H8.52344C7.47458 46 6.60389 45.1897 6.5286 44.1436L4.80875 20.2455Z"
            fill="#56E7FF"
        />
        <path
            d="M17.3055 22.5778C17.2794 22.0081 17.7342 21.5319 18.3045 21.5319H29.6955C30.2658 21.5319 30.7206 22.0081 30.6944 22.5778L29.6187 46H18.3813L17.3055 22.5778Z"
            fill="#016EB7"
        />
        <path
            d="M33.5357 9.71336L24 2.13044L0.991811 20.4269C0.390038 20.9054 0.28471 21.7881 0.756555 22.3984C1.2284 23.0087 2.09874 23.1155 2.70051 22.637L24 5.69935L45.2995 22.637C45.9013 23.1155 46.7716 23.0087 47.2435 22.3984C47.7153 21.7881 47.61 20.9054 47.0082 20.4269L40.3699 15.148C40.373 15.1159 40.3746 15.0832 40.3746 15.05V5.89362C40.3746 5.34133 39.9268 4.89362 39.3746 4.89362H34.6123C34.0678 4.89362 33.6234 5.32924 33.6125 5.87363L33.5357 9.71336Z"
            fill="#303C69"
        />
    </Fragment>
);

export default IconHome;
