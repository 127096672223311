import get from 'lodash.get';

/**
 * Formats the condition page data for google analytics
 * @param condition
 * @param relatedTreatments
 * @returns
 */
const formatSelectedConditionAnalytics = (condition: any, relatedTreatments: any[] = []) => ({
    // @ts-ignore
    category_page: `/${get(condition, 'slug')}`,
    // products
    items: relatedTreatments.map((treatmentCacheItem) => ({
        // Id is either default variant or the current treatment id.
        id: get(treatmentCacheItem, 'defaultVariant') || get(treatmentCacheItem, 'id'),
        name: get(treatmentCacheItem, 'title'),
        category: get(condition, 'title'),
    })),
});

export default formatSelectedConditionAnalytics;
