import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { hasDatePassed } from '../../helpers/dateFunctions/dateFunctions';

/**
 * Calculates the difference between now and the end time.
 * @param {string} endTime - The time to count down to (YYYY-MM-DD HH:mm:ss format).
 * @returns {number}
 */
const getDiff = (endTime) => {
    const format = 'YYYY-MM-DD HH:mm:ss';

    // Gets the current and end time as dayjs objects.
    const now = dayjs();
    const end = dayjs(endTime, format);

    const diff = end.diff(now, 'millisecond');
    return diff;
};

/**
 * Displays a countdown with hours minutes and seconds.
 * @returns {}
 */
const Countdown = ({
    endTime, // IMPORTANT - End time has to be passed in in the local timezone format.
    onExpire,
    showUnits,
    delaySeconds,
}) => {
    // Create state to store the duration.
    const [duration, setDuration] = useState(dayjs.duration(getDiff(endTime), 'millisecond'));

    // Store the callback for use in the useEffect
    const cb = useCallback(onExpire, []);

    // Get the hours minutes and seconds from the duration in state.
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = Math.floor(duration.asMinutes() % 60);
    const seconds = Math.floor(duration.asSeconds() % 60);

    // On every duration change.
    useEffect(() => {
        let timer;
        const cleanTimer = () => {
            if (timer) {
                clearTimeout(timer);
            }
        };

        // If the date has passed, clear the timer and run the callback.
        if (hasDatePassed(endTime)) {
            cleanTimer();

            if (cb) {
                cb();
            }

            return cleanTimer;
        }

        // Set a timeout to set the duration.
        timer = setTimeout(() => setDuration(dayjs.duration(getDiff(endTime), 'millisecond')), delaySeconds * 1000);
        return cleanTimer;
    }, [duration, cb, endTime, delaySeconds]);

    // If the date has passed, don't display the component.
    if (hasDatePassed(endTime)) {
        return null;
    }

    // If the units should be shown after each number.
    if (showUnits) {
        return (
            <span className="whitespace-nowrap">
                {hours ? (
                    <span>
                        {hours} {hours === 1 ? 'hour' : 'hours'}
                    </span>
                ) : null}{' '}
                {hours || minutes ? (
                    <span>
                        {minutes} {minutes === 1 ? 'minute' : 'minutes'}
                    </span>
                ) : null}{' '}
                {!hours && (minutes || seconds) ? (
                    <span>
                        {seconds} {seconds === 1 ? 'second' : 'seconds'}
                    </span>
                ) : null}
            </span>
        );
    }

    return (
        <span className="whitespace-nowrap">
            {hours ? <span>{hours < 10 ? `0${hours}` : hours} : </span> : null}
            {hours || minutes ? <span>{minutes < 10 ? `0${minutes}` : minutes} : </span> : null}
            {minutes || seconds ? <span>{seconds < 10 ? `0${seconds}` : seconds}</span> : null}
        </span>
    );
};

Countdown.defaultProps = {
    onExpire: undefined,
    showUnits: false,
    delaySeconds: 1,
};

Countdown.propTypes = {
    /** End time of the count down, please ensure this is local timezone already */
    endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onExpire: PropTypes.func,
    showUnits: PropTypes.bool,
    delaySeconds: PropTypes.number,
};

export default Countdown;
