import React, { Fragment } from 'react';

const IconCalender = () => (
    <Fragment>
        <path
            d="M2 11C2 7.134 5.13401 4 9 4H39C42.866 4 46 7.134 46 11V41C46 44.866 42.866 48 39 48H9C5.13401 48 2 44.866 2 41V11Z"
            fill="#56E7FF"
        />
        <path d="M2 11C2 7.13401 5.14008 4 9.01357 4H38.9864C42.8599 4 46 7.13401 46 11V16H2V11Z" fill="#303C69" />
        <path
            d="M15.6809 3.15086C15.6809 1.96297 14.633 1 13.3404 1C12.0478 1 11 1.96297 11 3.15086V7.45258C11 8.64046 12.0478 9.60344 13.3404 9.60344C14.633 9.60344 15.6809 8.64046 15.6809 7.45258V3.15086Z"
            fill="#90CDF5"
        />
        <path
            d="M35.3404 3.15086C35.3404 1.96297 34.2926 1 33 1C31.7074 1 30.6596 1.96297 30.6596 3.15086V7.45258C30.6596 8.64046 31.7074 9.60344 33 9.60344C34.2926 9.60344 35.3404 8.64046 35.3404 7.45258V3.15086Z"
            fill="#90CDF5"
        />
    </Fragment>
);

export default IconCalender;
