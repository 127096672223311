import React, { useMemo } from 'react';

import getStarRating from './helpers/getStarRating';

enum Sizes {
    small = 'w-[0.75rem] h-[0.75rem]',
    medium = 'w-[1rem] h-[1rem]',
}

interface Props {
    rating: number; // Number between 1 and 5
    className?: string; // Layout only classes
    size?: keyof typeof Sizes;
}

/**
 * Displays star SVGs in img tags to indicate a rating out of 5.
 */
const StarRating = ({ rating, className = '', size = 'small' }: Props) => {
    const stars = useMemo(() => getStarRating(rating), [rating]);

    return (
        <div className={`flex gap-[2px] ${className}`}>
            {stars.map((star, index) => (
                <img key={index} className={Sizes[size]} src={star} loading="lazy" alt="Star" />
            ))}
        </div>
    );
};

export default StarRating;
