import React, { Fragment } from 'react';

const IconTruck = () => (
    <Fragment>
        <path
            d="M12 15.1429C12 14.444 12.5861 13.8775 13.3091 13.8775H43.1133C43.6844 13.8775 44.1897 14.2354 44.3616 14.7618L47.9392 25.712C47.9795 25.8352 48 25.9637 48 26.093V32.8571C48 33.5559 47.4139 34.1224 46.6909 34.1224H13.3091C12.5861 34.1224 12 33.5559 12 32.8571V15.1429Z"
            fill="#56E7FF"
        />
        <path
            d="M23.3332 35.7551C23.3332 38.0995 21.3931 40 18.9998 40C16.6066 40 14.6665 38.0995 14.6665 35.7551C14.6665 33.4107 16.6066 31.5102 18.9998 31.5102C21.3931 31.5102 23.3332 33.4107 23.3332 35.7551Z"
            fill="#303C69"
        />
        <path
            d="M40.6666 35.7551C40.6666 38.0995 38.7265 40 36.3332 40C33.94 40 31.9999 38.0995 31.9999 35.7551C31.9999 33.4107 33.94 31.5102 36.3332 31.5102C38.7265 31.5102 40.6666 33.4107 40.6666 35.7551Z"
            fill="#303C69"
        />
        <path
            d="M36.973 17.5311C36.973 16.8293 37.5342 16.2603 38.2265 16.2603H40.4146C40.9743 16.2603 41.4661 16.6365 41.6199 17.182L43.4105 23.5361C43.6393 24.348 43.0381 25.1561 42.2053 25.1561H38.2265C37.5342 25.1561 36.973 24.5871 36.973 23.8853V17.5311Z"
            fill="#016EB7"
        />
        <path
            d="M0.126454 10.5756C-0.35362 9.32935 0.592754 8 1.96001 8H31.7715C32.853 8 33.7297 8.85345 33.7297 9.90623V26.4269C33.7297 27.4797 32.853 28.3331 31.7715 28.3331H8.32426C7.50799 28.3331 6.77733 27.8402 6.49071 27.0962L0.126454 10.5756Z"
            fill="#016EB7"
        />
    </Fragment>
);

export default IconTruck;
