import React from 'react';

const IconBlocked = () => (
    <path
        fillRule="evenodd"
        d="M11.518 8.37204L39.628 36.482C42.3653 33.0599 44 28.7224 44 24C44 12.9543 35.0457 4 24 4C19.2776 4 14.9401 5.63471 11.518 8.37204ZM36.8312 39.3422L8.65782 11.1688C5.74881 14.6427 4 19.1158 4 24C4 35.0457 12.9543 44 24 44C28.8842 44 33.3573 42.2512 36.8312 39.3422ZM7.2034 6.85723C11.5301 2.61745 17.4614 0 24 0C37.2548 0 48 10.7452 48 24C48 30.5386 45.3825 36.47 41.1428 40.7966C36.789 45.2395 30.7151 48 24 48C10.7452 48 0 37.2548 0 24C0 17.2849 2.76046 11.211 7.2034 6.85723Z"
    />
);

export default IconBlocked;
