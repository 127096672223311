import React from 'react';

const IconStatusInfo = () => (
    <path
        data-testid="icon-status-info"
        fillRule="evenodd"
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM23.9041 16C25.2848 16 26.4041 14.8807 26.4041 13.5C26.4041 12.1193 25.2848 11 23.9041 11C22.5234 11 21.4041 12.1193 21.4041 13.5C21.4041 14.8807 22.5234 16 23.9041 16ZM20.4035 18.5014C19.5751 18.5014 18.9035 19.173 18.9035 20.0014C18.9035 20.8299 19.5751 21.5014 20.4035 21.5014H22.4046V31.5041H19.9034C19.0749 31.5041 18.4034 32.1756 18.4034 33.0041C18.4034 33.8325 19.0749 34.5041 19.9034 34.5041H27.9058C28.7343 34.5041 29.4058 33.8325 29.4058 33.0041C29.4058 32.1756 28.7343 31.5041 27.9058 31.5041H25.4046V20.0014C25.4046 19.173 24.733 18.5014 23.9046 18.5014H20.4035Z"
    />
);

export default IconStatusInfo;
