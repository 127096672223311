import React, { Fragment } from 'react';

const IconMedicalCross = () => (
    <Fragment>
        <path
            data-testid="icon-medical-cross"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12C20.8954 12 20 12.8954 20 14V20H14C12.8954 20 12 20.8954 12 22V26C12 27.1046 12.8954 28 14 28H20V34C20 35.1046 20.8954 36 22 36H26C27.1046 36 28 35.1046 28 34V28H34C35.1046 28 36 27.1046 36 26V22C36 20.8954 35.1046 20 34 20H28V14C28 12.8954 27.1046 12 26 12H22ZM14 21.5H21.5V14C21.5 13.7239 21.7239 13.5 22 13.5H26C26.2761 13.5 26.5 13.7239 26.5 14V21.5H34C34.2761 21.5 34.5 21.7239 34.5 22V26C34.5 26.2761 34.2761 26.5 34 26.5H26.5V34C26.5 34.2761 26.2761 34.5 26 34.5H22C21.7239 34.5 21.5 34.2761 21.5 34V26.5H14C13.7239 26.5 13.5 26.2761 13.5 26V22C13.5 21.7239 13.7239 21.5 14 21.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z"
        />
    </Fragment>
);

export default IconMedicalCross;
