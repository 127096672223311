import React from 'react';

import * as styles from './AccountMenuWrapper.module.css';

interface Props {
    children: React.ReactNode;
}

const AccountMenuWrapper = ({ children }: Props) => <div className={styles.AccountMenuWrapper}>{children}</div>;

export default AccountMenuWrapper;
