import React from 'react';
import clx from 'classnames';

import * as styles from './Avatar.module.css';

enum Borders {
    foreground = 'borderForeground',
}

interface Props {
    src: string;
    alt: string;
    size?: 'small' | 'medium' | 'large' | 'xlarge';
    shape?: 'pill' | 'halfPillUp' | 'halfPillDown';
    border?: keyof typeof Borders;
    className?: string;
    loading?: 'lazy' | 'eager';
}

const Avatar = ({ size = 'medium', border, className, src, alt, loading = 'lazy', shape = 'pill' }: Props) => {
    const classNames = clx(styles.avatar, styles[shape], styles[size], border ? styles[Borders[border]] : '', className, styles.pill);

    return (
        <div className={classNames}>
            <img src={src} alt={alt} loading={loading} />
        </div>
    );
};

export default Avatar;
