export const fontColors = {
    foundation: 'text-content-foundation',
    quiet: 'text-content-quiet',
    accent: 'text-content-accent',
    highlight: 'text-content-highlight',
    shouty: 'text-content-shouty',
    white: 'text-neutrals-white',
    error500: 'text-status-error-500',
    info500: 'text-status-info-500',
    warning500: 'text-status-warning-500',
    success500: 'text-status-success-500',
    price: 'text-products-price',
    saving: 'text-products-saving',
};

export const getFontColorClass = (color?: keyof typeof fontColors) => (color ? fontColors[color] : '');
