import React, { Fragment } from 'react';

const IconSearch = () => (
    <Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.684 33.9839C27.4613 36.5004 23.4058 38 19 38C8.50659 38 0 29.4934 0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19C38 23.4058 36.5004 27.4613 33.9839 30.684L39.3166 36.0168L36.0168 39.3166L30.684 33.9839ZM33.3333 19C33.3333 26.9161 26.9161 33.3333 19 33.3333C11.0839 33.3333 4.66667 26.9161 4.66667 19C4.66667 11.0839 11.0839 4.66667 19 4.66667C26.9161 4.66667 33.3333 11.0839 33.3333 19Z"
            fill="#56E7FF"
        />
        <path
            d="M34.2719 38.8419C33.9094 38.4793 33.9094 37.8915 34.2719 37.529L37.529 34.2719C37.8915 33.9094 38.4793 33.9094 38.8419 34.2719L47.7281 43.1581C48.0907 43.5207 48.0907 44.1085 47.7281 44.471L44.4711 47.7281C44.1085 48.0906 43.5207 48.0906 43.1582 47.7281L34.2719 38.8419Z"
            fill="#016EB7"
        />
    </Fragment>
);

export default IconSearch;
