import React from 'react';

const IconStatusWarning = () => (
    <path
        data-testid="icon-status-warning"
        fillRule="evenodd"
        d="M21.9661 3.21C22.8562 1.59666 25.1438 1.59667 26.034 3.21001L47.6994 42.4779C48.5696 44.055 47.4464 46 45.6655 46H2.33453C0.553567 46 -0.569599 44.055 0.300588 42.4779L21.9661 3.21ZM24 17.5C24.8284 17.5 25.5 18.1716 25.5 19V31C25.5 31.8284 24.8284 32.5 24 32.5C23.1716 32.5 22.5 31.8284 22.5 31V19C22.5 18.1716 23.1716 17.5 24 17.5ZM24 39C25.1046 39 26 38.1046 26 37C26 35.8954 25.1046 35 24 35C22.8954 35 22 35.8954 22 37C22 38.1046 22.8954 39 24 39Z"
    />
);

export default IconStatusWarning;
