import React, { ComponentProps } from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import clx from 'classnames';

import Button, { Variants, Sizes } from '../Button/Button';

type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;
type PickedButtonProps = Pick<ComponentProps<typeof Button>, 'variant' | 'size' | 'disabled'>;

interface Props extends PickedButtonProps, CustomGatsbyLinkProps {
    children: React.ReactNode;
    title: string;
}

/**
 * Wrapper for using next/link and our button styles
 */
const ButtonLink = ({ children, to, title, size = 'medium', variant = 'primary', className, disabled, ...props }: Props) => {
    const classNames = clx(
        'button inline-block',
        disabled ? 'cursor-not-allowed' : Variants[variant],
        disabled && variant !== 'none' ? 'button-disabled' : Variants[variant],
        Sizes[size],
        className
    );

    const onClick = disabled ? (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault() : undefined;

    return (
        <Link to={to} title={title} className={classNames} onClick={onClick} {...props}>
            {children}
        </Link>
    );
};

export default ButtonLink;
