import React, { Fragment } from 'react';

const IconProfileDark = () => (
    <Fragment>
        <circle cx="24" cy="24" r="24" fill="#303C69" />
        <path
            d="M5.95544 39.8244C8.4356 32.373 15.4653 27 23.7498 27C32.1858 27 39.3206 32.5712 41.6756 40.235C37.289 45.0084 30.9938 48 24 48C16.8076 48 10.3541 44.8362 5.95544 39.8244Z"
            fill="#56E7FF"
        />
        <path
            d="M30.1431 28.1181C28.1691 31.0621 24.811 32.9999 21.0002 32.9999C18.1018 32.9999 15.4652 31.8789 13.5004 30.0469C16.4462 28.1202 19.9673 27 23.75 27C25.9951 27 28.1479 27.3946 30.1431 28.1181Z"
            fill="#016EB7"
        />
        <circle cx="24" cy="20.0001" r="10" fill="white" />
    </Fragment>
);

export default IconProfileDark;
