import React from 'react';

import ImagePreload from '../../_ui/_blocks/ImagePreload';

interface Props {
    src: string;
    alt: string;
    className?: string;
    preload?: boolean; // If preload tags should be added to the head of the page.
    width?: number;
    height?: number;
}

/**
 * Component used to display a treatment image.
 */
const TreatmentImage = ({ src, alt, className, width, height, preload = false }: Props) => (
    <div className={className}>
        {preload ? (
            <ImagePreload src={src} alt={alt} renderImage width={width} height={height} />
        ) : (
            <img src={src} alt={alt} width={width} height={height} />
        )}
    </div>
);

export default TreatmentImage;
