/* eslint-disable react/button-has-type */
import React from 'react';
import clx from 'classnames';

export enum Variants {
    primary = 'button-primary',
    secondary = 'button-secondary',
    bordered = 'button-bordered',
    ghost = 'button-ghost',
    none = 'button-reset',
}

export enum Sizes {
    small = 'button-size-small',
    medium = 'button-size-medium',
    large = 'button-size-large',
    none = '',
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    variant?: keyof typeof Variants;
    size?: keyof typeof Sizes;
    type?: 'button' | 'submit' | 'reset';
    className?: string; // Should only be used for layout purposes e.g. flex or inline-block.
    title: string;
}

const Button = ({ children, type = 'button', variant = 'primary', size = 'medium', className, disabled, ...rest }: Props) => {
    const classNames = clx('button', disabled && variant !== 'none' ? 'button-disabled' : Variants[variant], Sizes[size], className);

    return (
        <button type={type} className={classNames} disabled={disabled} {...rest}>
            {children}
        </button>
    );
};

export default Button;
