import { roundToNearestHalf } from '../../../../../helpers/maths';

type returnType = string[];

const FULL_STAR_SRC = '/images/icons/star-full.svg';
const HALF_STAR_SRC = '/images/icons/star-half.svg';
const EMPTY_STAR_SRC = '/images/icons/star-empty.svg';

/**
 * Creates an array of star image sources based on the rating out of 5.
 */
const getStarRating = (rating: number): returnType => {
    const roundedRating = roundToNearestHalf(rating);

    if (roundedRating > 4.5) {
        return Array(5).fill(FULL_STAR_SRC);
    }

    if (!roundedRating) {
        return Array(5).fill(EMPTY_STAR_SRC);
    }

    const isInteger = Number.isInteger(roundedRating);

    // Create an array of full stars.
    const starsArray = isInteger ? Array(roundedRating).fill(FULL_STAR_SRC) : Array(roundedRating - 0.5).fill(FULL_STAR_SRC);

    // Add a half star if the rating is not an integer.
    if (!isInteger) {
        starsArray.push(HALF_STAR_SRC);
    }

    // Add empty stars until the array is 5 items long.
    while (starsArray.length < 5) {
        starsArray.push(EMPTY_STAR_SRC);
    }

    return starsArray;
};

export default getStarRating;
