import { useStaticQuery, graphql } from 'gatsby';

/**
 * Grabs the site meta from the gatsby config and returns it so that we can use it sitewide.
 * @returns
 */
const useSiteMetaData = () => {
    const {
        site: { siteMetadata },
        craftcms: {
            defaultDeliveryCosts: { ...defaultDeliveryCosts },
        },
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                }
            }
            craftcms {
                defaultDeliveryCosts: globalSet(handle: "defaultDeliveryCosts") {
                    ... on CraftCMS_defaultDeliveryCosts_GlobalSet {
                        minimumDeliveryCost
                    }
                }
            }
        }
    `);

    return {
        ...siteMetadata,
        ...defaultDeliveryCosts,
    };
};

export default useSiteMetaData;
