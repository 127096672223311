import { ProductVariant } from '../../../../../../../../types/api/products/Product';
import { Basket } from '../../../../../../../../types/api/basket/Basket';
import Patient from '../../../../../../../../types/api/patient/Patient';
import getOutOfStockBlocker, { OutOfStockBlocker } from '../getOutOfStockBlocker/getOutOfStockBlocker';
import getGenderBlocker, { GenderBlocker } from '../getGenderBlocker/getGenderBlocker';
import getBlockedByBasketBlocker, { BlockedByBasketBlocker } from '../getBlockedByBasketBlocker/getBlockedByBasketBlocker';
import getAlreadyInBasketBlocker, { AlreadyInBasketBlocker } from '../getAlreadyInBasketBlocker/getAlreadyInBasketBlocker';

type Blockers = Array<OutOfStockBlocker | GenderBlocker | BlockedByBasketBlocker | AlreadyInBasketBlocker>;

/**
 * Gets any basket blockers.
 */
export const getBasketBlockers = (variant: ProductVariant, basket: Basket, patient: Patient) => {
    const blockers = [
        getOutOfStockBlocker(variant),
        getGenderBlocker(variant.gender, patient?.personal.gender),
        getBlockedByBasketBlocker(variant, basket),
        getAlreadyInBasketBlocker(variant.id, basket),
    ];

    return blockers.filter((blocker) => !!blocker) as Blockers;
};

export default getBasketBlockers;
