import React from 'react';
import clx from 'classnames';

import * as styles from './Tag.module.css';

export type TagColours =
    | 'regular'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'bestSeller'
    | 'specialOffer'
    | 'bundlePack'
    | 'newProduct'
    | 'multiBuyOffer'
    | 'newPackaging'
    | 'sale';

export const borderRadiusList = {
    full: 'borderRadiusFull',
    small: 'borderRadiusSmall',
};

export interface TagProps {
    as?: 'span' | 'div';
    children: React.ReactNode;
    size?: 'medium' | 'large' | 'custom';
    color?: TagColours;
    className?: string;
    borderRadius?: keyof typeof borderRadiusList;
}

const Tag = ({ as = 'span', children, size = 'medium', color = 'regular', className, borderRadius = 'full' }: TagProps) => {
    const classNames = clx(styles.tag, styles[size], styles[color], styles[borderRadiusList[borderRadius]], className);

    return React.createElement(as, { className: classNames }, children);
};

export default Tag;
