import React, { Fragment } from 'react';

const IconClipboard = () => (
    <Fragment>
        <rect x="7" y="4.66666" width="34" height="43.3333" rx="4" fill="#303C69" />
        <rect x="9.72015" y="7.33337" width="28.56" height="36" rx="2" fill="white" />
        <path
            d="M26.7201 1.09804C26.7201 0.491609 26.171 0 25.5868 0H22.4134C21.8293 0 21.2801 0.491609 21.2801 1.09804V2.23529C21.2801 2.84172 20.8821 3.33333 20.2979 3.33333C19.7137 3.33333 19.2401 3.82494 19.2401 4.43137V8.23529C19.2401 8.84172 19.7137 9.33333 20.2979 9.33333H27.7023C28.2865 9.33333 28.7601 8.84172 28.7601 8.23529V4.43137C28.7601 3.82494 28.2865 3.33333 27.7023 3.33333C27.1181 3.33333 26.7201 2.84172 26.7201 2.23529V1.09804Z"
            fill="#016EB7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7474 16.4352C34.3457 17.0203 34.3514 17.9746 33.7601 18.5667L23.0122 29.3284C22.1178 30.2239 20.6564 30.2239 19.762 29.3284L15.5997 25.1607C15.0084 24.5687 15.0141 23.6144 15.6124 23.0292C16.2108 22.4441 17.1752 22.4498 17.7665 23.0419L21.3871 26.6671L31.5933 16.4478C32.1846 15.8557 33.149 15.8501 33.7474 16.4352Z"
            fill="#016EB7"
        />
    </Fragment>
);

export default IconClipboard;
