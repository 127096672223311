import React, { Fragment } from 'react';

const IconExit = () => (
    <Fragment>
        <path
            d="M1.5 3C0.671573 3 0 3.67157 0 4.5V43.5C0 44.3284 0.671573 45 1.5 45H25.5C26.3284 45 27 44.3284 27 43.5V31.5C27 30.6716 26.3284 30 25.5 30C24.6716 30 24 30.6716 24 31.5V42H3V6H24V16.5C24 17.3284 24.6716 18 25.5 18C26.3284 18 27 17.3284 27 16.5V4.5C27 3.67157 26.3284 3 25.5 3H1.5Z"
            fill="#303C69"
        />
        <path
            d="M36.9689 14.6049C36.3365 14.0698 35.39 14.1487 34.8549 14.7811C34.3198 15.4135 34.3987 16.36 35.0311 16.8951L41.6551 22.5H10.5C9.67157 22.5 9 23.1716 9 24C9 24.8284 9.67157 25.5 10.5 25.5H41.6551L35.0311 31.1049C34.3987 31.64 34.3198 32.5865 34.8549 33.2189C35.39 33.8513 36.3365 33.9302 36.9689 33.3951L46.7189 25.1451C47.0557 24.8601 47.25 24.4412 47.25 24C47.25 23.5588 47.0557 23.1399 46.7189 22.8549L36.9689 14.6049Z"
            fill="#56E7FF"
        />
    </Fragment>
);

export default IconExit;
